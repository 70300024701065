@import '../../../UI/global.module.scss';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  //background: #AAE2FF;
	padding: 146px 0 64px;
	overflow: hidden;
	background-image: url('/home/hero/hero.webp');
	background-repeat: no-repeat;
  background-position: center;
	background-size: cover;

  .container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 50%;
    width: 100%;
    max-width: 1232px;

    // Text
    .text {
			display: flex;
			flex-direction: column;
			gap: 56px;
      z-index: 1;

			.title {
				font-size: 56px;
				font-family: Roca One;
				font-weight: 400;
				line-height: 60px;
				letter-spacing: 1.5px;
				color: #FCFFFE;
			}

			.sectionButtons {
				display: flex;
				flex-direction: row;
				gap: 32px;

				a {
					margin-bottom: 16px;
				}
			}

      .social {
				width: 782px;
				margin-right: 50px;
      }

      .list {
				.social_item {
					display: flex;
					flex-direction: column;
					gap: 8px;

					.socialQty {
						font-size: 34px;
						font-family: Roca One;
						font-weight: 400;
						line-height: 34px;
						letter-spacing: 1px;
						color: #FCFFFE;
					}

					.socialDesc {
						font-size: 15px;
						font-family: NT Bau;
						font-weight: 400;
						line-height: 130%;
						letter-spacing: -2%;
						color: #FCFFFE;
					}
				}

        li:first-child {
          width: 132px;
        }
      }
    }

    // Image
    .image {
      height: auto;
      box-sizing: border-box;

      position: absolute;
			top: 59px;
			right: -308px;

      & > span {
        vertical-align: top;
        display: block !important;
      }
    }
  }
}


.aqua {
	color: $aqua;
}

@media only screen and (max-width: 768px) {
  .wrapper {
		padding: 120px 0 0;
		background-position: 72% center;

    .container {
      display: grid;
      grid-template-columns: 1fr;

      // Text
      .text {
				padding: 0 16px;
				margin-bottom: 48px;

				.title {
					font-size: 34px;
					line-height: 34px;
					letter-spacing: 1px;
				}

				.sectionButtons {
					display: flex;
					flex-direction: column;
					gap: 36px;

					a {
						margin-bottom: 0;
					}
				}

				.social {
					width: 100%;
					max-width: 100%;
				}

        .list {
					display: grid;
					grid-template-columns: 120px 120px;
					justify-content: start;

					.social_item {
						align-items: flex-start;
					}

          li:first-child {
            width: auto;
          }
        }
      }

      // Image
      .image {
        position: relative;
        top: 0px;
        right: 0px;
				
      }
    }
  }
}
